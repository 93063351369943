// 中文语言包
export default {
  action: {
    action: "Action",
    add:"Add",
    edit: "Edit",
    copy: "Copy",
    delete: "Delete",
    default: "Default",
    complete: "Complete",
    recover_from_this_verision: "Recover From This Verision",
    add_verify_list: "Add Verify List",
    save: "Save",
    verify: "Verify",
    preview_pdf: "Preview Pdf",
    back_home: "Back Home",
    recover_from_this_version:"Recover From This Version",
    upload_to_library:"Upload To Library",
    import_from_spec:"Import From Spec"
  },
  confirm:{
    tips:"Tips",
    confirm: "Confirm",
    cancel: "Cancel",
    ok:"Ok",
    delete: "Are you sure to delete this data?",
    update: "Are you sure to update this data?",
    copy:"This will Copy the data. Continue?",
    save_success:"Save Success",
    requested_by_empty:"Requested By Can Not Be Empty!",
    items_revised_empty:"Revised Notes Can Not Be Empty!",
    edited_by_empty:"Edited By Can Not Be Empty!",
    reason_empty:"Reason Can Not Be Empty!",
    item_code_empty:"Item Code Not Be Empty",
    item_code_already_exists:"Item Code Already Exists",
    item_code_no_audited_record:"There is no audited spec record for {{}} itemCode. Please check the product code or verify if the corresponding spec has been audited.",
    save_fail:"Save Fail",
    verify_tips:"Confirm this verification?",
    verify_success:"Verify Success",
    verify_fail:"Verify Fail",
    import_success:"Import Success",
    import_fail:"Import Fail",
    import_spec_fail:"Import Spec Fail，No Item Code Record Was Found",
    success:"Success",
    no_verified_item_code:"There are no verified spec records for the current item code",
    item_code_checking:"Item Code Checking",
    print_option_label:"Print Option",
    cutomer_signature:"Cutomer Signature",
    verifed_signature:"Verifed Signature",
    print_tips:"Should the customer‘s signature field be printed?",
    verify_print_tips:"Should the approver‘s signature be printed?”",
    no_revision_date:"Please Input revision date",
    add_to_verify_list:"Add To Verify List"
  },
  list: {
    lot:"Lot",
    lot_number: "Lot Number",
    number: "Number",
    ty_number:"Ty Number",
    verify: "Verify",
    item_code:"Item Code",
    product_name: "Product Name",
    product_description: "Product Description",
    product_type: "Product Type",
    sds_type: "SDS",
    rev: "Rev",
    create_by: "Create By",
    create_time: "Create Time",
    update_by: "Update By",
    update_time: "Update Time",
    verified_name:"verified Name",
    verified_by: "Verified By",
    verified_time: "Verified Time",
    china_lot:"China Lot",
    quantity:"Quantity",
    shipping_method:"Shipping Method",
    shipment_date:"Shipment Date",
  },
  view: {
    user_manager:"User Manager",
    login:"Login",
    login_out:"Login Out",
    detail_manager: "Detail Manager",
    placeholder:"Please Input",
    select_placeholder:"Please Select",
    data_placeholder:"Data Select",
    search:"Search",
    list:"List",
  },
  content: {
    expiration_date:"Expiration Date",
    shelf_life_note:"${other_notes.shelflife_number} months unopened",
    add_user:"Add User",
    user_name:"User Name",
    nick_name:"Nick Name",
    role:"Role",
    manager_user:"Manager User",
    normal_user:"Normal User",
    read_only_user:"Read Only User",
    phone:"Phone",
    reset_password:"Reset Password",
    new_password:"New Password",
    confirm_password:"Confirm Password",
    us_user:"America User",
    ch_user:"China User",
    add_user_info:"Add User Info",
    edit_user_info:"Edit User Info",
    rev:"Rev",
    coa_rev_update_date:"Revision Date",
    coa_rev_update_version:"Revision Version",
    coa_rev_verify_name:"Verified Name",
    rev_version:"Rev Version",
    revised_notes:"Revised Notes",
    po_number:"Po Number",
    customer_code:"Customer Code",
    custom:"Customer",
    is_show_note:"Is Show Note",
    manufacuture_date:"Manufacture Date",
    product_type: "Product Type",
    product_description: "Product Description",
    list: "List",
    please_input: "Please Input",
    lot: "Lot",
    item_code: "Item Code",
    sds_type: "SDS",
    product_name: "Product Name",
    version_number: "Version Number",
    customer_product_name: "Customer Product Name",
    botanical_name: "Botanical Name",
    inci_name: "INCI Name",
    appearance: "Appearance",
    solubility: "Solubility",
    ph: "pH",
    specific_gravity: "Specific Gravity",
    color: "Color",
    odor: "Odor",
    customs_classification: "Customs Classification",
    print_title: "Print Title",
    print_please_verify: "Print Please Verify",
    supplier_or_producer: "Supplier Or Producer",
    storage: "Storage",
    composition_information_on_ingredients: "Composition Information On Ingredients",
    substance_name: "Substance Name",
    cas_number: "CAS Number",
    einecs_number: "Einecs Number",
    r_phrase: "R Phrase",
    s_phrase: "S Phrase",
    base_info: "Base Info",
    processing: "Processing",
    physical_properties: "Physical Properties",
    confidential: "Confidential",
    custom_formula: "Custom Formula",
    document_notes: "Document Notes",
    custom_field: "Custom Field",
    general_specification: "General Specification",
    ingredients: "Ingredients",
    common_other_name: "Common Other Name",
    part_used: "Part Used",
    percentage: "Percentage",
    custom_field_before_regular_data: "Custom Field Before Regular Data",
    country_of_origin: "Country Of Origin",
    extraction_ratio: "Extraction Ratio",
    major_constituents_in_native_herbs: "Major Constituents In Native Herbs",
    important_constitue_in_plant: "Important Constituent in Plant",
    homogeneity: "Homogeneity",
    extraction_process: "Extraction Process",
    processing_aid: "Processing Aid",
    solvent_for_extraction: "Solvent For Extraction",
    solvent: "Solvent",
    carrier: "Carrier",
    other_ingredients: "Other Ingredients",
    pesticides: "Pesticides",
    certification: "Certification",
    preservative: "Preservative",
    product_grade: "Product Grade",
    analysis: "Analysis",
    standard1: "Standard1",
    standard2: "Standard2",
    standard3: "Standard3",
    mesh_size: "Mesh Size",
    loss_on_drying: "Loss On Drying",
    bulk_density: "Bulk Density",
    identification: "Identification",
    bioactives_level: "Bioactives Level",
    moisture: "Moisture",
    specifica_gravity: "Specifica Gravity",
    refractive_index: "Refractive Index",
    optical_rotation: "Optical Rotation",
    acid_value: "Acid Value",
    peroxide_value: "Peroxide Value",
    saponification_value: "Saponification Value",
    unsaponifiable_master: "Unsaponifiable Master",
    moisture_volatiles: "Moisture Volatiles",
    total_heavy_metals: "Total Heavy Metals",
    arsenic: "Arsenic",
    cadmium: "Cadmium",
    lead: "Lead",
    mercury: "Mercury",
    taste: "Taste",
    microbiological_tests: "Microbiological Tests",
    total_plate_count: "Total Plate Count",
    yeast_and_mold: "Yeast And Mold",
    ecoli: "Ecoli",
    salmonella: "Salmonella",
    staph_aureus: "Staph Aureus",
    total_coliforms: "Total Coliforms",
    fatty_acid: "Fatty Acid",
    caprylic: "Caprylic",
    capric: "Capric",
    lauric: "Lauric",
    myristic: "Myristic",
    myristoleic: "Myristoleic",
    palmitica: "Palmitica",
    palmitoleic: "Palmitoleic",
    stearic: "Stearic",
    oleic: "Oleic",
    linoleicacid: "Linoleicacid",
    a_linolenic: "A Linolenic",
    y_linoleic: "Y Linoleic",
    punicicacid: "Punicicacid",
    arachidic: "Arachidic",
    arachidonic: "Arachidonic",
    eicosapentaenoic: "Eicosapentaenoic",
    docosahexaenoic: "Docosahexaenoic",
    composition: "Composition",
    others: "Others",
    other_notes: "Other Notes",
    notes:"Notes",
    shelflife_number: "Shelflife Number",
    shelf_life: "Shelf Life",
    package_info: "Package Info",
    revision: "Revision",
    approved_by: "Approved By",
    edited_by: "Edited By",
    items_revised: "Items Revised",
    reason: "Reason",
    requested_by: "Requested By",
    revision_date: "Revision Date",
    punicic_acid:"Punicic Acid",
    linoleic_acid:"Linoleic Acid",
    note1:"Note1",
    note2:"Note2",
    note3:"Note3",
  }
}
